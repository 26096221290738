import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'

const Container = styled.div`
  text-align: right;
  padding-top: 0.5rem;
  padding-right: 0.25rem;
`

const LangSelector = () => (
  <Container>
    <p><Link to='/'>português 🇧🇷</Link> • <Link to='en'>english 🇺🇸</Link></p>
  </Container>
)

export default LangSelector
