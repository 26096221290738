import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import styled from 'styled-components'
import { StaticQuery, graphql } from 'gatsby'

import iaWriterDuoSpaceRegular from './../fonts/iAWriterDuospace-Regular.otf'
import iaWriterDuoSpaceBold from './../fonts/iAWriterDuospace-Bold.otf'

import 'bulma/css/bulma.css'
import './style.css'

const HeaderLine = styled.div`
  background-color: #B92B37;
  height: 0.5rem;
`

const Layout = ({ children }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <>
        <Helmet title={data.site.siteMetadata.title}>
          <html lang='pt-br' />
        </Helmet>
        <HeaderLine />
        <div className='container is-max-desktop'>
          {children}
        </div>
      </>
    )}
  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
